import React, { useEffect, useState } from 'react';
import './App.css';
import './Buttons.css';
import Avatar from './avatar.jpeg';

function App() {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (!loaded) {
      setTimeout(() => {
        try {
          document.getElementsByTagName("frameset")[0].setAttribute("rows", "*");
          console.log('frameset')
        } catch (ex) { console.log(ex) }
        console.log('Loaded')

      }, 1000);
      setLoaded(true);
    }
  }, [loaded]);

  return (
    <div className="App">
      <header className="App-header">
        <img src={Avatar} className="App-logo" alt="logo" />

        <p className="title">
          Renan Caldas @ Software Developer
        </p>

        <ul className="icon-list">
          <li className="icon-item">
            <a href="https://github.com/renancaldas" className="icon-link" target="_blank" rel="noreferrer"><i className="fab fa-github"></i></a>
          </li>
          <li className="icon-item">
            <a href="linkedin.com/in/renancaldas/" className="icon-link" target="_blank" rel="noreferrer"><i className="fab fa-linkedin-in"></i></a>
          </li>
          <li className="icon-item">
            <a href="https://www.facebook.com/renan.caldas.oliveira/" className="icon-link" target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a>
          </li>
          <li className="icon-item">
            <a href="https://twitter.com/renancalda/" className="icon-link" target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a>
          </li>

          <li className="icon-item">
            <a href="https://www.instagram.com/reinstanan/" className="icon-link" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a>
          </li>
        </ul>


      </header>
    </div>
  );
}

export default App;
